import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'

import {useLayout} from '../../core'
import {useLocation} from 'react-router-dom'
import {AppRoutesStrings} from '../../../../app/routing/AppRoutes'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const location = useLocation()
  const {currentUser} = useAuth()

  if (location.pathname.includes(AppRoutesStrings.CONTROL_PANEL)) {
    return (
      <div className='app-navbar flex-shrink-0'>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                <KTIcon iconName='user' className={btnIconClass} />
                {currentUser?.name}
              </div>
            </div>
          </div>

          <HeaderUserMenu />
        </div>
      </div>
    )
  }

  if (location.pathname.includes(AppRoutesStrings.TOOLS)) {
    return (
      <div className='app-navbar flex-shrink-0'>
        <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div>
      </div>
    )
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      {!!currentUser && (
        <>
          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              className={btnClass}
            >
              <KTIcon iconName='element-plus' className={btnIconClass} />
            </div>
            <HeaderNotificationsMenu />
          </div>

          <div className={clsx('app-navbar-item', itemClass)}>
            <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
              <KTIcon iconName='message-text-2' className={btnIconClass} />
              <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
            </div>
          </div>

          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              className={clsx('cursor-pointer symbol', userAvatarClass)}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
            </div>
            <HeaderUserMenu />
          </div>
        </>
      )}

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
