export enum RecordState {
  Pending = 0,
  Active = 2,
  Locked = 4,
  Archived = 6,
  Deleted = 10,
}

export const GetRecordState = (state: RecordState): string => {
  switch (state) {
    case RecordState.Pending:
      return 'Pending'

    case RecordState.Locked:
      return 'Locked'

    case RecordState.Archived:
      return 'Archived'

    case RecordState.Deleted:
      return 'Deleted'

    default:
      return 'Active'
  }
}

export enum MagicPagesTypes {
  Home = 1,
  ContactUs = 2,
  Copyright = 3,
  TermsAndConditions = 4,
  PrivacyPolicy = 6,
  CookiePolicy = 7,
  RestorePassword = 8,
  Login = 9,
  Register = 10,
  ChangeLogs = 15,

  Hashing = 30,
  Dehashing = 31,
  PasswordReliability = 32,
  BlockchainSimulator = 33,
  CryptoPredictiveSimulator = 34,
  CryptoTradingSimulator = 35,

  WhyWeNeedBitcoin = 50,
  Decentralization = 51,
  CreateABitcoinWallet = 52,
  AHardMathProblem = 53,
  EllipticCurvesIntro = 54,
  EllipticCurvesKeygeneration = 55,
  DigitalSignatures = 56,
  TransactionStorage = 57,
  BuildingConsensus = 58,
  CryptographicHash = 59,
  BuildingBlocks = 60,
  TheBlockchain = 61,
  FinalWords = 62,

  Custom = 99.
}

export enum ToolsTypes {
  Hashing = 1,
  DeHashing = 2,
  PasswordReliability = 5,
  BlockchainSimulator = 8,
  CryptoPredictiveSimulator = 10,
}

export enum UserRoleTypes {
  Admin = 1,
  Bot = 4,
  SuperAdmin = 8,
}

export enum SettingsTypes {
  Copyright = 1,

  LogoUrl = 2,
  LogoTitle = 3,

  HomeScreenVideo = 4,
  HomeScreenContent = 5,

  EmailLayout = 8,
}

export enum LogLevelsTypes {
  Trace = 0,

  Debug = 1,

  Information = 2,

  Warning = 3,
  Error = 4,

  None = 5,
}

export enum AssetsInterval {
  m1 = 'm1',
  m5 = 'm5',
  m15 = 'm15',
  m30 = 'm30',
  h1 = 'h1',
  h2 = 'h2',
  h6 = 'h6',
  h12 = 'h12',
  d1 = 'd1',
}

export enum TimeInMiliSecod {
  s1 = 1000,
  m1 = 60000,
  h1 = 3600000,
  d1 = 86400000,
}

export enum HashMethod {
  DES = 1,
  MD4 = 2,
  MD5 = 3,
  SHA_1 = 4,
  SHA_256 = 5,
  SHA_384 = 6,
  SHA_512 = 7,
  Whirlpool = 8,
}

export enum StatsItemType {
  BlockChainBlockBuilt = 0,

  CryptoSimSuccessAttempts = 5,
  CryptoSimFailedAttempts = 6,

  DehashUsingCounter = 10,
  DehashNotFoundCounter = 11,

  HashCounter = 15
}

export enum NotificationTypes {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

export enum BlockStatuses {
  PreviousBlockInvalid = 1,
  HashInvalid = 2,
  InvalidTransactionsSignature = 3,
  TransactionsWithInsufficientBalance = 4
}