import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useLayout } from '../../_metronic/layout/core'
import { PublicRoutesStrings } from './PulibcPagesRoutes'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'

export const ToolsRoutesStrings = {
  DASHBOARD: 'dashboard',
  HASHING: 'hashing',
  DEHASHING: 'dehashing',
  PASSWORD_RELIABILITY: 'password-reliability',
  INFO: ':pageName/:type',

  BLOCKCHAIN_SIMULATOR: 'blockchain-simulator',
  BLOCKCHAIN_SIMULATOR_INFO: 'blockchain-simulator/:pageName/:type',

  CRYPTO_PREDICTIVE_SIMULATOR: 'crypto-predictive-simulator',
  CRYPTO_TRADING_SIMULATOR: 'crypto-trading-simulator',
}

const ToolsRoutes = () => {
  const { config, setLayoutType, setToolbarType } = useLayout()
  if (config.layoutType !== 'light-sidebar') {
    setLayoutType('light-sidebar')
  }

  const Dashboard = lazy(() => import('../areas/client/tools/dashboard/Dashboard'))
  const HashingPage = lazy(() => import('../areas/client/tools/hashing/HashingPage'))
  const ToolsInfoPage = lazy(() => import('../areas/client/tools/tools-info-page/ToolsInfoPage'))
  const DeHashingPage = lazy(() => import('../areas/client/tools/dehashing/DeHashingPage'))
  const PasswordReliability = lazy(
    () => import('../areas/client/tools/password-reliability/PasswordReliability')
  )

  const BlockchainSimulator = lazy(
    () => import('../areas/client/tools/blockchainSimulator/BlockchainSimulator')
  )
  const CryptoPredictiveSimulatorPage = lazy(
    () => import('../areas/client/tools/crypto-predictive-simulator/CryptoPredictiveSimulatorPage')
  )
  const CryptoTradingSimulatorPage = lazy(
    () => import('../areas/client/tools/crypto-trading-simulator/CryptoTradingSimulatorPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path=''
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.DASHBOARD}
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.HASHING}
          element={
            <SuspensedView>
              <HashingPage />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.DEHASHING}
          element={
            <SuspensedView>
              <DeHashingPage />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.PASSWORD_RELIABILITY}
          element={
            <SuspensedView>
              <PasswordReliability />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.INFO}
          element={
            <SuspensedView>
              <ToolsInfoPage />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}
          element={
            <SuspensedView>
              <BlockchainSimulator />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR_INFO}
          element={
            <SuspensedView>
              <ToolsInfoPage />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.CRYPTO_PREDICTIVE_SIMULATOR}
          element={
            <SuspensedView>
              <CryptoPredictiveSimulatorPage />
            </SuspensedView>
          }
        />

        <Route
          path={ToolsRoutesStrings.CRYPTO_TRADING_SIMULATOR}
          element={
            <SuspensedView>
              <CryptoTradingSimulatorPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to={PublicRoutesStrings.ERROR} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { ToolsRoutes }

