/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link, Outlet, Route, Routes } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { AppRoutesStrings } from '../../routing/AppRoutes'
import { PublicRoutesStrings } from '../../routing/PulibcPagesRoutes'
import { MagicPagesTypes } from '../../services/enums'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { Registration } from './components/Registration'
import { UIRoutes } from '../../routing'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <Link to={AppRoutesStrings.HOME} className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/CapitalWave.png')} className='h-45px' />
        </Link>

        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
            <li className='menu-item'>
              <Link to={AppRoutesStrings.HOME} className='menu-link px-2' reloadDocument={false}>
                Home
              </Link>
            </li>

            <li className='menu-item'>
              <a href={`/pages/${UIRoutes.CONTACT_US}/${MagicPagesTypes.ContactUs}`} className='menu-link px-2'>
                Contact Us
              </a>
            </li>

            <li className='menu-item'>
              <a
                href={`/pages/privacy-policy/${MagicPagesTypes.PrivacyPolicy}`}
                className='menu-link px-2'
              >
                Privacy Policy
              </a>
            </li>

            <li className='menu-item'>
              <a href={`/pages/terms/${MagicPagesTypes.TermsAndConditions}`} className='menu-link px-2'>
                Terms and Conditions
              </a>
            </li>

            <li className='menu-item'>
              <a
                href={`/pages/privacy-policy/${MagicPagesTypes.PrivacyPolicy}`}
                className='menu-link px-2'
              >
                Cookie Policy
              </a>
            </li>

            <li className='menu-item'>
              <a href={`/pages/copyright/${MagicPagesTypes.Copyright}`} className='menu-link px-2'>
                Copyright Disclosure
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }

