import React from 'react';

export interface IRoute {
  path: string;
  element: React.ReactElement;
}

export enum Areas {
  CONTROL_PANEL = 'controlpanel',
}

export enum UIRoutes {
  LOGIN = 'login',
  ACCOUNT = 'account',

  PORTFOLIOS = 'portfolios',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  RESTORE_PASSWORD = 'restore-password/:code',
  TOOLS = 'tools',
  TOOLS_PUBLIC = 'tools/:name/:id',
  PAGE_BASE_VIEW = 'pages',
  PAGE_VIEW = 'pages/:name/:type',
}

export const LearnerPortalRoutes = {
  DASHBOARD: 'dashboard',

  PROFILE: 'profile',

  MESSAGES: 'messages',
  MESSAGES_CHAT: 'messages/:id',

  PAGE_VIEW: 'pages/:name/:type',

  TOOLS: 'tools',
  TOOLS_VIEW: 'tools/:name/:id',
};

export const ControlPanelRoutes = {
  DASHBOARD: 'dashboard',

  TASKS: 'tasks',

  USERS: 'users',
  USER_BASE_EDIT: 'user/edit',
  USER_EDIT: 'user/edit/:id',
  USER_CREATE: 'user/create',

  LEARNERS: 'learners',
  LEARNERS_VIEW: 'learners/:name/:id',

  COURSES: 'courses',
  COURSE_BASE_EDIT: 'course/edit',
  COURSE_EDIT: 'course/edit/:id',
  COURSE_CREATE: 'course/create',

  MAGIC_PAGES: 'magic-pages',
  MAGIC_PAGE_CREATE: 'magic-pages/create',
  MAGIC_PAGE_EDIT: 'magic-pages/:id',

  SYSTEM_SETTINGS: 'system-settings',

  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_TEMPLATES_BASE_EDIT: 'email-templates/edit',
  EMAIL_TEMPLATES_EDIT: 'email-templates/edit/:id',

  SUBSCRIBES: 'subscribes',

  KNOWLEDGE_BASE: 'knowledge-base',
  KNOWLEDGE_BASE_EDIT: 'knowledge-base/edit',
  KNOWLEDGE_EDIT: 'knowledge-base/edit/:id',
  KNOWLEDGE_CREATE: 'knowledge-base/create',


  TOOLS: 'tools',
  TOOL_EDIT: 'tool/edit',
  TOOL_VIEW: 'tool/edit/:id',

  TOOL_TIPS: 'toolTips',
};
