import {AxiosResponse} from 'axios'
import {APIRoutes} from '../../constants/apiRoutes'
import {IResultResponse} from '../../models/response/result.model'
import {ISystemSetting} from '../../models/systemSetting/system-setting.model'
import RequestsService from '../base/requests.service'
import {SettingsTypes} from '../enums'
import CommonService from '../base/common.service'

export default class SystemSettingsService {
  static async getAll(): Promise<AxiosResponse<IResultResponse<ISystemSetting[]>>> {
    return RequestsService.getMethod<IResultResponse<ISystemSetting[]>>(
      `${APIRoutes.SYSTEM_SETTINGS}`
    )
  }

  static async getByType(
    id: SettingsTypes
  ): Promise<AxiosResponse<IResultResponse<ISystemSetting>>> {
    return RequestsService.getMethod<IResultResponse<ISystemSetting>>(
      `${APIRoutes.SYSTEM_SETTINGS}/${id}`
    )
  }

  static async update(
    systemSetting: ISystemSetting
  ): Promise<AxiosResponse<IResultResponse<ISystemSetting>>> {
    return RequestsService.putMethod<IResultResponse<ISystemSetting>>(
      `${APIRoutes.SYSTEM_SETTINGS}/${systemSetting.id}`,
      systemSetting
    )
  }

  static async uploadFile(
    id: SettingsTypes,
    file: FormData
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      `${APIRoutes.SYSTEM_SETTINGS}/${id}/file`,
      file
    )
  }
}

export const getValue = (array: ISystemSetting[], type: SettingsTypes) => {
  return array.filter((f) => f.id === type)[0]?.value
}
