import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { useLayout } from '../../_metronic/layout/core';
import ToolTipsEdit from '../areas/controlPanel/toolTips/ToolTipsEdit';
import ToolTipsList from '../areas/controlPanel/toolTips/ToolTipsList';
import { PublicRoutesStrings } from './PulibcPagesRoutes';

export const ControlPanelRoutesStrings = {
  DASHBOARD: 'dashboard',

  USERS: 'users',
  USER_BASE_EDIT: 'users/edit',
  USER_EDIT: 'users/edit/:id',

  MAGIC_PAGES: 'content-pages',
  MAGIC_PAGE_BASE_EDIT: 'content-pages/edit',
  MAGIC_PAGE_EDIT: 'content-pages/edit/:id',

  TOOL_TIPS: 'tool-tips',
  TOOL_TIPS_EDIT: 'tool-tips/edit/:id',

  SYSTEM_SETTINGS: 'system-settings',

  // EMAIL_TEMPLATES: 'email-templates',
  // EMAIL_TEMPLATES_BASE_EDIT: 'email-templates/edit',
  // EMAIL_TEMPLATES_EDIT: 'email-templates/edit/:id',
}

const ControlPanelRoutes = () => {
  const { config, setLayoutType, setToolbarType } = useLayout()
  if (config.layoutType !== 'dark-sidebar') {
    setLayoutType('dark-sidebar')
  }
  const DashboardPage = lazy(() => import('../areas/controlPanel/dashboard/DashboardPage'));
  const UsersList = lazy(() => import('../areas/controlPanel/users/UsersList'));
  const UserEdit = lazy(() => import('../areas/controlPanel/users/UserEdit'));
  const MagicPagesList = lazy(() => import('../areas/controlPanel/magic-pages/MagicPagesList'));
  const MagicPagesEdit = lazy(() => import('../areas/controlPanel/magic-pages/MagicPagesEdit'));
  const SystemSettingsPage = lazy(() => import('../areas/controlPanel/system-settings/SystemSettingsPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path=''
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.DASHBOARD}
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.USERS}
          element={
            <SuspensedView>
              <UsersList />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.USER_BASE_EDIT}
          element={
            <SuspensedView>
              <UserEdit />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.USER_EDIT}
          element={
            <SuspensedView>
              <UserEdit />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.MAGIC_PAGES}
          element={
            <SuspensedView>
              <MagicPagesList />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.MAGIC_PAGE_BASE_EDIT}
          element={
            <SuspensedView>
              <MagicPagesEdit />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.MAGIC_PAGE_EDIT}
          element={
            <SuspensedView>
              <MagicPagesEdit />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.TOOL_TIPS}
          element={
            <SuspensedView>
              <ToolTipsList />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.TOOL_TIPS_EDIT}
          element={
            <SuspensedView>
              <ToolTipsEdit />
            </SuspensedView>
          }
        />

        <Route
          path={ControlPanelRoutesStrings.SYSTEM_SETTINGS}
          element={
            <SuspensedView>
              <SystemSettingsPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to={PublicRoutesStrings.ERROR} />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { ControlPanelRoutes };

