import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppRoutesStrings } from '../../app/routing/AppRoutes'
import { reInitMenu } from '../helpers'
import { DrawerMessenger, InviteUsers, ThemeModeProvider } from '../partials'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { HeaderWrapper } from './components/header'
import { Notifications } from './components/notifications/Notifications'
import { ScrollTop } from './components/scroll-top'
import { Sidebar } from './components/sidebar'
import { ToolbarWrapper } from './components/toolbar'
import { PageDataProvider } from './core'

const MasterLayout = () => {
  const location = useLocation()

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />

            <Notifications />

            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              {(location.pathname.includes(AppRoutesStrings.CONTROL_PANEL)
                || (location.pathname.includes(AppRoutesStrings.TOOLS)))
                && <Sidebar />
              }

              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        <DrawerMessenger />

        <InviteUsers />
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }

