import { FC } from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import { List } from './components/List';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Controlpanel',
    path: '/controlpanel',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '/',
    path: '/',
    isSeparator: true,
    isActive: false,
  },
];

const ToolTipsList: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Tool Tips</PageTitle>
      <List className='card-xxl-stretch mb-5 mb-xl-8' />
    </>
  );
};

export default ToolTipsList;
