import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { App } from '../App'
import { AuthPage, Logout, useAuth } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { ControlPanelRoutes } from './ControlPanelRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { PulibcPagesRoutes } from './PulibcPagesRoutes'
import { ToolsRoutes } from './ToolsRoutes'

const { PUBLIC_URL } = process.env

export const AppRoutesStrings = {
  HOME: '/',
  ERROR: 'error',
  TOOLS: 'tools',
  CONTROL_PANEL: 'controlpanel', 
  LOGOUT: 'logout',
  AUTH: 'auth',
  PORTAL: 'portal',
}

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path={`${AppRoutesStrings.ERROR}/*`} element={<ErrorsPage />} />
          <Route path={`${AppRoutesStrings.TOOLS}/*`} element={<ToolsRoutes />} />
          <Route path={`${AppRoutesStrings.CONTROL_PANEL}/*`} element={<ControlPanelRoutes />} />

          <Route path={`${AppRoutesStrings.LOGOUT}`} element={<Logout />} />
          <Route path={`${AppRoutesStrings.AUTH}/*`} element={<AuthPage />} />

          {currentUser && (
            <>
              <Route path={`${AppRoutesStrings.PORTAL}/*`} element={<PrivateRoutes />} />
            </>
          )}

          <Route path='/*' element={<PulibcPagesRoutes />} />
          {/* <Route index element={<Navigate to='/' />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }

