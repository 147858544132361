import {AxiosResponse} from 'axios'

import RequestsService from './base/requests.service'
import {APIRoutes} from '../constants/apiRoutes'
import {IResultResponse} from '../models/response/result.model'
import {IUser} from '../models/user/user.model'

export default class UserService {
  static async getMy(): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.getMethod<IResultResponse<IUser>>(`${APIRoutes.USERS}/my`)
  }

  static async create(user: IUser): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.postMethod<IResultResponse<IUser>>(APIRoutes.USERS, user)
  }

  static async update(user: IUser): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.putMethod<IResultResponse<IUser>>(`${APIRoutes.USERS}/${user.id}`, user)
  }

  static async delete(userId: string): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.deleteMethod<IResultResponse<any>>(`${APIRoutes.USERS}/${userId}`)
  }

  static async updatePassword(
    userId: string,
    password: string
  ): Promise<AxiosResponse<IResultResponse<IUser>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      `${APIRoutes.USERS}/${userId}/password/change`,
      {
        newPassword: password,
      }
    )
  }
}
