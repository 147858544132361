import React, { useContext } from 'react'
import { NotificationTypes } from '../../../../app/services/enums'
import { KTSVG } from '../../../helpers'
import { INotification, PageDataContext } from '../../core'


export const Notifications: React.FC = () => {
  const { notifications, deleteNotification } = useContext(PageDataContext)

  const notification = (notif: INotification, index: number) => {
    return (
      <div
        key={`notification-${index}`}
        className={`alert alert-${notif?.type}  alert-dismissible d-flex p-5`}
      >
        <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
          {notif?.type === NotificationTypes.Success && <KTSVG path="/media/icons/duotune/general/gen043.svg" className={`svg-icon-${notif?.type} svg-icon-2hx`} />}
          {notif?.type === NotificationTypes.Warning && <KTSVG path="/media/icons/duotune/general/gen044.svg" className={`svg-icon-${notif?.type} svg-icon-2hx`} />}
          {notif?.type === NotificationTypes.Danger && <KTSVG path="/media/icons/duotune/general/gen034.svg" className={`svg-icon-${notif?.type} svg-icon-2hx`} />}
        </span>
        <div className="text-dark pe-0 pe-sm-10 align-self-center">
          <h5>{notif.message}</h5>
          {notif?.detailed && <div className='text-mute fs-6'>{notif.detailed}</div>}
        </div>

        <button
          type="button"
          className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
          // data-bs-dismiss="alert"
          onClick={() => deleteNotification(index)}
        >
          {/* <KTSVG path="/media/icons/duotune/abstract/abs012.svg" className="svg-icon svg-icon-2hx" /> */}
          <KTSVG path="/media/icons/duotune/general/gen034.svg" className={`svg-icon svg-icon-2hx`} />
        </button>
      </div>
    )
  }

  return (
    <div className='position-fixed top-0 end-0' style={{zIndex: '100'}}>
      <div className='d-flex flex-column align-items-end mt-5 me-5'>
        {notifications?.map((notif, i) => notification(notif, i))}
      </div>
    </div>
  );
}
