import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useLayout } from '../../_metronic/layout/core'
import { MagicPagesTypes } from '../services/enums'
import { PublicLayout } from '../../_metronic/layout/PublicLayout'
import { UIRoutes } from '.'


export const PublicRoutesStrings = {
  HOME: '',
  
  PAGES: 'pages',
  PAGE_BY_TYPE: 'pages/:pageName/:type',

  CONTACT_US: `/pages/${UIRoutes.CONTACT_US}/${MagicPagesTypes.ContactUs}`,

  ERROR: '/error/404',
}

const PulibcPagesRoutes = () => {
  const { config, setLayoutType, setToolbarType } = useLayout()
  if (config.layoutType !== 'dark-header') {
    setLayoutType('dark-header')
  }

  const Index = lazy(() => import('../areas/client/pages/Index'))
  const Page = lazy(() => import('../areas/client/pages/Page'))

  
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path={PublicRoutesStrings.HOME}
          element={
            <SuspensedView>
              <Index />
            </SuspensedView>
          }
        />

        <Route
          path={PublicRoutesStrings.PAGE_BY_TYPE}
          element={
            <SuspensedView>
              <Page />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to={PublicRoutesStrings.ERROR} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PulibcPagesRoutes }

