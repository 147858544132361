import { useFormik } from 'formik'
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { IToolTip } from '../../../../models/tool-tip/tool-tip.model'
import { NotificationTypes, ToolsTypes } from '../../../../services/enums'
import ToolTipsService from '../../../../services/tool-tips/tool-tips.service'
import Editor from '../../../../components/Editor/Editor'
import { PageDataContext } from '../../../../../_metronic/layout/core'


const initialValues: IToolTip = {
  id: '',
  toolId: ToolsTypes.Hashing,
  fieldName: '',
  content: '',
}

const Edit: React.FC = () => {
  const { setNotificationsOver } = useContext(PageDataContext)

  const { id } = useParams()
  const [toolTipLoading, setToolTipLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const toolTipSchema = Yup.object().shape({
    toolId: Yup.string().required('Select tooltip type'),
    fieldName: Yup.string().required('Field name is required'),
    content: Yup.string().required('Content is required'),
    // password: id
    //   ? Yup.string()
    //   : Yup.string().required('Password is required')
  })

  const formik = useFormik<IToolTip>({
    initialValues,
    validationSchema: toolTipSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      ToolTipsService.update(values)
        .then(response => {
          setNotificationsOver([{message: 'Tooltip has been updated', type: NotificationTypes.Success}]);
          setLoading(false)
        })
        .catch(error => {
          setNotificationsOver([{message: 'Tooltip hasn\'t been updated', detailed: `${error}`, type: NotificationTypes.Danger}]);
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    if (id) {
      setToolTipLoading(true);
      ToolTipsService.getById(id)
        .then(response => {
          formik.setValues(response.data.result);
          setToolTipLoading(false);
        })
        .catch(error => {
          setNotificationsOver([{message: 'Tooltip hasn\'t been loaded', detailed: `${error}`, type: NotificationTypes.Danger}]);
          setToolTipLoading(false);
        });
    }
  }, [id]);

  return (
    <div className='card mb-5 mb-xl-10'>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              ToolTip Type
              {toolTipLoading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('toolId')}
              >
                {Object.keys(ToolsTypes)
                  .filter(i => parseInt(i))
                  .map(i => parseInt(i))
                  .map(i =>
                    <option key={`tool-type-${i}`} value={i}>{ToolsTypes[i]}</option>
                  )}
              </select>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
              Field Name
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Field Name'
                {...formik.getFieldProps('fieldName')}
              />
              {formik.touched.fieldName && formik.errors.fieldName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fieldName}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Content</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <Editor
                placeholder=''
                height='35em'
                setContent={(value) => formik.setFieldValue('content', value)}
                className='w-100'
                {...formik.getFieldProps('content')}
              />
              {formik.touched.content && formik.errors.content && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.content}</div>
                </div>
              )}
            </div>
          </div>

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { Edit }

