import { FC } from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import { Edit } from './components/Edit';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Controlpanel',
    path: '/controlpanel',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '/',
    path: '/',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Tool Tips',
    path: 'tool-tips',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '/',
    path: '/',
    isSeparator: true,
    isActive: false,
  },
];

const ToolTipsEdit: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Edit</PageTitle>
      <Edit />
    </>
  );
};

export default ToolTipsEdit;
