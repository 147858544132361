import { useIntl } from 'react-intl'
import { MegaMenu } from './MegaMenu'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MenuItem } from './MenuItem'
import { AppRoutesStrings } from '../../../../../app/routing/AppRoutes'
import { PublicRoutesStrings } from '../../../../../app/routing/PulibcPagesRoutes'
import { ToolsRoutesStrings } from '../../../../../app/routing/ToolsRoutes'

export function MenuInner() {
  const intl = useIntl()

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.HOME' })} to={AppRoutesStrings.HOME} />

      <MenuInnerWithSub title={intl.formatMessage({ id: 'MENU.TOOLS' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.DASHBOARD}`} menuPlacement='bottom-start' menuTrigger='click'>
        <MenuItem icon='element-11' title={intl.formatMessage({ id: 'MENU.TOOLS.DASHBOARD' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.DASHBOARD}`} />
        <MenuItem icon='code' title={intl.formatMessage({ id: 'MENU.TOOLS.HASHING' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.HASHING}`} />
        <MenuItem icon='key' title={intl.formatMessage({ id: 'MENU.TOOLS.DEHASHING' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.DEHASHING}`} />
        <MenuItem icon='lock' title={intl.formatMessage({ id: 'MENU.TOOLS.PASSWORD_RELIABILITY' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.PASSWORD_RELIABILITY}`} />
        <MenuItem icon='scan-barcode' title={intl.formatMessage({ id: 'MENU.TOOLS.BLOCKCHAIN_SIMULATOR' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}`} />
        <MenuItem icon='screen' title={intl.formatMessage({ id: 'MENU.TOOLS.CRYPTO_PREDICTIVE_SIMULATOR' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.CRYPTO_PREDICTIVE_SIMULATOR}`} />
        <MenuItem icon='archive' title={intl.formatMessage({ id: 'MENU.TOOLS.CRYPTO_TRADING_SIMULATOR' })} to={`${AppRoutesStrings.TOOLS}/${ToolsRoutesStrings.CRYPTO_TRADING_SIMULATOR}`} />
      </MenuInnerWithSub>

      <MenuItem title={intl.formatMessage({ id: 'MENU.CONTACT_US' })} to={PublicRoutesStrings.CONTACT_US} />
    </>
  )
}
