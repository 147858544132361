import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import {WithChildren} from '../../../../_metronic/helpers'
import {IUser} from '../../../models/user/user.model'
import UserService from '../../../services/user.service'
import AuthService from '../../../services/auth/auth.service'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: IUser | undefined
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
}

const initAuthContextPropsState = {
  auth: {api_token: AuthService.geAuthToken()} as AuthModel,
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const token = AuthService.geAuthToken()
  const [auth, setAuth] = useState<AuthModel | undefined>({api_token: token} as AuthModel)
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      AuthService.setTokenLocalStorage(auth.api_token)
    } else {
      AuthService.clearLocalStorage()
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    if (auth && auth.api_token) {
      requestUser()
    } else {
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  const requestUser = async () => {
    try {
      if (!didRequest.current) {
        //todo: implement
        UserService.getMy()
          .then(response => {
            const result = response.data.result

            setCurrentUser({
              id: result.id,
              name: result.name,
              email: result.email,
              photoUrl: result.photoUrl,
              role: result.role,
            } as IUser)
          })
          .catch((e) => {
            throw e
          })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
