/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useEffect, useState } from 'react'
import { NotificationTypes } from '../../../app/services/enums'
import { WithChildren } from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface INotification {
  message: string
  detailed?: string
  type?: NotificationTypes
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  notifications?: INotification[]
  deleteNotification: (index: number) => void
  setNotificationsOver: (_newNotifications: INotification[]) => void
}

export const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => { },
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => { },
  setPageDescription: (_description: string) => { },
  deleteNotification: (index: number) => { },
  setNotificationsOver: (_newNotifications: INotification[]) => { },
})

const PageDataProvider: FC<WithChildren> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [notifications, setNotifications] = useState<INotification[]>([])

  const deleteNotification = (index: number) => {
    if (notifications.length < 1) return

    if (!index) {
      if (notifications.length > 1) {
        setNotifications([...notifications.slice(1)]);
      } else {
        setNotifications([]);
      }
    } else {
      if (notifications.length === 1) {
        setNotifications([]);
      } else {
        if (index === notifications.length - 1) {
          setNotifications([...notifications.slice(0, index)]);
        } else {
          setNotifications([...notifications.slice(0, index), ...notifications.slice(index + 1)]);
        }
      }
    }
  }

  const setNotificationsOver = (_newNotifications: INotification[]) => {
    setNotifications([...notifications, ..._newNotifications]);
  }

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    notifications,
    deleteNotification,
    setNotificationsOver,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({ children, description, breadcrumbs }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: FC<WithChildren> = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export { PageDataProvider, PageDescription, PageTitle, usePageData }

