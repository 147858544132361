import { AxiosResponse } from 'axios'
import { APIRoutes } from '../../constants/apiRoutes'
import { IPagination } from '../../models/pagination/pagination.model'
import { IResultResponse } from '../../models/response/result.model'
import { IToolTip } from '../../models/tool-tip/tool-tip.model'
import RequestsService from '../base/requests.service'
import { ToolsTypes } from '../enums'


export default class ToolTipsService {
  static async getAll(
    tool?: ToolsTypes,
    pagination?: IPagination,
  ): Promise<AxiosResponse<IResultResponse<IToolTip[]>>> {
    return RequestsService.getMethod<IResultResponse<IToolTip[]>>(
      `${APIRoutes.TOOL_TIPS}`, { tool, ...pagination }
    )
  }

  static async getById(
    id: string,
  ): Promise<AxiosResponse<IResultResponse<IToolTip>>> {
    return RequestsService.getMethod<IResultResponse<IToolTip>>(
      `${APIRoutes.TOOL_TIPS}/${id}`,
    )
  }

  static async update(
    toolTip: IToolTip,
  ): Promise<AxiosResponse<IResultResponse<IToolTip>>> {
    return RequestsService.putMethod<IResultResponse<IToolTip>>(
      `${APIRoutes.TOOL_TIPS}`, toolTip
    )
  }  

  // static async delete(userId: string): Promise<AxiosResponse<IResultResponse<IUser>>> {
  //   return RequestsService.deleteMethod<IResultResponse<any>>(`${APIRoutes.USERS}/${userId}`)
  // }

  // static async updatePassword(
  //   userId: string,
  //   password: string
  // ): Promise<AxiosResponse<IResultResponse<IUser>>> {
  //   return RequestsService.putMethod<IResultResponse<any>>(
  //     `${APIRoutes.USERS}/${userId}/password/change`,
  //     {
  //       newPassword: password,
  //     }
  //   )
  // }
}
