/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { AppRoutesStrings } from '../../../../../app/routing/AppRoutes'
import { ControlPanelRoutesStrings } from '../../../../../app/routing/ControlPanelRoutes'
import { ToolsRoutesStrings } from '../../../../../app/routing/ToolsRoutes'
import { MagicPagesTypes } from '../../../../../app/services/enums'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemLinkWithSub } from './SidebarMenuItemLinkWithSub'


const SidebarMenuMain = () => {
  const intl = useIntl()
  const location = useLocation()

  if (location.pathname.includes(AppRoutesStrings.CONTROL_PANEL)) {
    return (
      <>
        <SidebarMenuItem
          to={ControlPanelRoutesStrings.DASHBOARD}
          icon='app-indicator'
          title='Dashboard'
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to={ControlPanelRoutesStrings.USERS}
          icon='people'
          title='Users'
          fontIcon='bi-people'
        />

        <SidebarMenuItem
          to={ControlPanelRoutesStrings.MAGIC_PAGES}
          icon='file-earmark'
          title='Content pages'
          fontIcon='bi-file-earmark'
        />

        <SidebarMenuItem
          to={ControlPanelRoutesStrings.TOOL_TIPS}
          icon='question-square'
          title='Tool Tips'
          fontIcon='bi-question-square'
        />

        <SidebarMenuItem
          to={ControlPanelRoutesStrings.SYSTEM_SETTINGS}
          icon='gear'
          title='System settings'
          fontIcon='bi-gear'
        />
      </>
    )
  }

  if (location.pathname.includes(AppRoutesStrings.TOOLS)) {
    return (
      <>
        <SidebarMenuItem
          to={ToolsRoutesStrings.DASHBOARD}
          icon='element-11'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItemLinkWithSub
          to={ToolsRoutesStrings.HASHING}
          title='Hashing'
          fontIcon='bi-hash'
          icon='code'
        >
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.HASHING}/${MagicPagesTypes.Hashing}`}
            title='Info'
            hasBullet={true}
          />
        </SidebarMenuItemLinkWithSub>

        <SidebarMenuItemLinkWithSub
          to={ToolsRoutesStrings.DEHASHING}
          icon='backward'
          title='Dehashing'
          fontIcon='bi-skip-backward-circle-fill'
        >
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.DEHASHING}/${MagicPagesTypes.Dehashing}`}
            title='Info'
            hasBullet={true}
          />
        </SidebarMenuItemLinkWithSub>

        <SidebarMenuItemLinkWithSub
          to={ToolsRoutesStrings.PASSWORD_RELIABILITY}
          icon='lock'
          title='Password Reliability'
          fontIcon='bi-lock'
        >
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.PASSWORD_RELIABILITY}/${MagicPagesTypes.PasswordReliability}`}
            title='Info'
            hasBullet={true}
          />
        </SidebarMenuItemLinkWithSub>

        <SidebarMenuItemLinkWithSub
          to={ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}
          icon='play'
          title='Blockchain Simulator'
          fontIcon='bi-play-circle-fill'
        >
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.BlockchainSimulator}`}
            title='Info'
            hasBullet={true}
          />

          <SidebarMenuItem to={'#'} title='INTRODUCTION' />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.WhyWeNeedBitcoin}`}
            title='Why we need Bitcoin?'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.Decentralization}`}
            title='Decentralization'
            hasBullet={true}
          />

          <SidebarMenuItem to={'#'} title='PROOF OF IDENTITY' />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.CreateABitcoinWallet}`}
            title='Create a Bitcoin Wallet'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.AHardMathProblem}`}
            title='A hard math problem'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.EllipticCurvesIntro}`}
            title='Elliptic Curves: Intro'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.EllipticCurvesKeygeneration}`}
            title='Elliptic Curves: Key generation'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.DigitalSignatures}`}
            title='Digital Signatures'
            hasBullet={true}
          />

          <SidebarMenuItem to={'#'} title='DECENTRALIZED DATABASE' />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.TransactionStorage}`}
            title='Transaction storage'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.BuildingConsensus}`}
            title='Building consensus'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.CryptographicHash}`}
            title='Cryptographic Hash'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.BuildingBlocks}`}
            title='Building Blocks'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.TheBlockchain}`}
            title='The Blockchain'
            hasBullet={true}
          />
          <SidebarMenuItem
            to={`${ToolsRoutesStrings.BLOCKCHAIN_SIMULATOR}/${MagicPagesTypes.FinalWords}`}
            title='Final Words'
            hasBullet={true}
          />
        </SidebarMenuItemLinkWithSub>

        <SidebarMenuItem
          to={ToolsRoutesStrings.CRYPTO_PREDICTIVE_SIMULATOR}
          icon='display'
          title='Crypto Predictive Simulator'
          fontIcon='bi-display'
        />

        <SidebarMenuItem
          to={ToolsRoutesStrings.CRYPTO_TRADING_SIMULATOR}
          icon='display'
          title='Crypto Trading Simulator'
          fontIcon='bi-pc-display-horizontal'
        />
      </>
    )
  }

  return null
}

export { SidebarMenuMain }
