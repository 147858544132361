export const APIBase = process.env.REACT_APP_API_URL;

const APIv1 = `${process.env.REACT_APP_API_URL}/v1`;
const PublicblockchainId = '00000000-0000-0000-0000-000000000000';

export const APIRoutesBase = {
  DASHBOARD: `${APIv1}/Dashboard`,
  CONTACT_US: `${APIv1}/Contacts`,
  AUTH: `${APIv1}/Auth`,
  SUNSCRIBERS: `${APIv1}/Subscribes`,
  EMAIL_TEMPLATES: `${APIv1}/EmailTemplates`,
  DEHASH: `${APIv1}/DeHash`,
  MAGIC_PAGES: `${APIv1}/MagicPages`,
  MAGIC_PAGES_HISTORY: `${APIv1}/MagicPagesHistory`,
  TOOL_TIPS: `${APIv1}/ToolTips`,
  NEWS: `${APIv1}/News`,
  STATS: `${APIv1}/Stats`,
  USERS: `${APIv1}/Users`,
  SYSTEM_SETTINGS: `${APIv1}/SystemSettings`,
  BLOCKCHAIN: `${APIv1}/Blockchain`,
  BLOCKCHAIN_WALLET: `${APIv1}/Blockchain/wallet`,
  BLOCKCHAIN_NAME_VERIFY: `${APIv1}/Blockchain/wallet/recipient`,
  BLOCKCHAIN_CREATE_BLOCK: `${APIv1}/Blockchain/block`,
  BLOCKCHAIN_GET_BLOCKS: `${APIv1}/Blockchain/block`,
  BLOCKCHAIN_GET_BALANCE_MAPS: `${APIv1}/Blockchain/balanceMaps`,
  CRYPTO_PREDICTIVE_SIMULATOR: `${APIv1}/CryptoPredictiveSimulator`,
}

export const APIRoutes = {
  ...APIRoutesBase,

  AUTH_LOGIN: `${APIRoutesBase.AUTH}/login`,
  AUTH_FORGOT_PASSWORD_REQUEST: `${APIRoutesBase.AUTH}/forgotPassword/request`,
  AUTH_FORGOT_PASSWORD_CHANGE: `${APIRoutesBase.AUTH}/forgotPassword/change`,

  MAGIC_PAGES_HISTORY_FOR_PAGE: `${APIRoutesBase.MAGIC_PAGES_HISTORY}/forPage`,

  NEWS_TAKE: `${APIRoutesBase.NEWS}/take`,

  // assets from coincap.io
  ASSETS: 'https://api.coincap.io/v2/assets',
  ASSETS_HISTORY: 'history?interval=',
}
