import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import { IToolTip } from '../../../../models/tool-tip/tool-tip.model'
import { NotificationTypes, ToolsTypes } from '../../../../services/enums'
import ToolTipsService from '../../../../services/tool-tips/tool-tips.service'
import { PageDataContext } from '../../../../../_metronic/layout/core'


interface IProps {
  className: string
}


const List: React.FC<IProps> = ({ className }) => {
  const { setNotificationsOver } = useContext(PageDataContext)

  const [toolTips, setToolTips] = useState<IToolTip[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    ToolTipsService.getAll()
      .then(response => {
        setToolTips(response.data.result);
        setLoading(false);
      })
      .catch(error => {
        setNotificationsOver([{message: 'Tooltips list hasn\'t been loaded', detailed: `${error}`, type: NotificationTypes.Danger}]);
        setLoading(false);
      });
  }, []);


  return (
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Tool Type</th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-120px'>Content</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>

            <tbody>
              {loading &&
                <tr><td>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </td></tr>
              }

              {toolTips?.map((toolTip) => <tr key={toolTip.id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <span className='text-dark fs-6'>
                        {ToolsTypes[toolTip.toolId]}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    <Link to={`edit/${toolTip.id}`} className='text-dark fw-bold text-hover-primary fs-6'>
                      {toolTip.fieldName}
                    </Link>
                  </span>
                </td>
                <td>
                  <span className='text-dark d-block fs-8'>
                    {toolTip.content}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' to={`edit/${toolTip.id}`}>
                      <KTIcon iconName='pencil' className='fs-3' />
                    </Link>
                  </div>
                </td>
              </tr>)}
            </tbody>

          </table>

        </div>
      </div>
    </div>
  )
}

export { List }

