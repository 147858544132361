/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppRoutesStrings } from '../../../../app/routing/AppRoutes'
import { MagicPagesTypes, NotificationTypes, SettingsTypes } from '../../../../app/services/enums'
import SystemSettingsService from '../../../../app/services/system-settings/system-settings.service'
import { ILayout, PageDataContext, useLayout } from '../../core'
import { UIRoutes } from '../../../../app/routing'

const Footer = () => {
  const { config } = useLayout()
  const { setNotificationsOver } = useContext(PageDataContext)
  const [copyrightInfo, setCopyrightInfo] = useState('')

  useEffect(() => {
    SystemSettingsService.getByType(SettingsTypes.Copyright)
      .then(response => {
        setCopyrightInfo(response.data.result.value);
      })
      .catch(error => {
        setNotificationsOver([{ message: 'Failed to load copyright', detailed: `${error}`, type: NotificationTypes.Danger }]);
      })
  }, [])


  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <div
          className='footer-p fw-semibold'
          dangerouslySetInnerHTML={{
            __html: copyrightInfo,
          }}>

        </div>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <Link to={AppRoutesStrings.HOME} className='menu-link px-2' reloadDocument={false}>
            Home
          </Link>
        </li>

        <li className='menu-item'>
          <a href={`/pages/${UIRoutes.CONTACT_US}/${MagicPagesTypes.ContactUs}`} className='menu-link px-2'>
            Contact Us
          </a>
        </li>

        <li className='menu-item'>
          <a
            href={`/pages/privacy-policy/${MagicPagesTypes.PrivacyPolicy}`}
            className='menu-link px-2'
          >
            Privacy Policy
          </a>
        </li>

        <li className='menu-item'>
          <a href={`/pages/terms/${MagicPagesTypes.TermsAndConditions}`} className='menu-link px-2'>
            Terms and Conditions
          </a>
        </li>

        <li className='menu-item'>
          <a
            href={`/pages/privacy-policy/${MagicPagesTypes.PrivacyPolicy}`}
            className='menu-link px-2'
          >
            Cookie Policy
          </a>
        </li>

        <li className='menu-item'>
          <a href={`/pages/copyright/${MagicPagesTypes.Copyright}`} className='menu-link px-2'>
            Copyright Disclosure
          </a>
        </li>
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }

