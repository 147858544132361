/* eslint-disable no-unused-expressions */

import React, {useRef} from 'react'
import JoditEditor from 'jodit-react'

interface IProps {
  value: string
  placeholder: string
  height: string
  setContent: (a: any) => void
  className: string
}

const config = (height: any, placeholder: any) => ({
  height,
  placeholder,
})

const Editor: React.FC<IProps> = ({value, placeholder, height, setContent, className}) => {
  const setRef = useRef(null)

  return (
    <JoditEditor
      config={config(height, placeholder)}
      ref={setRef}
      onChange={() => {}}
      value={value}
      onBlur={(newContent) => setContent(newContent)}
    />
  )
}

export default Editor
