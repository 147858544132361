import axios, {AxiosResponse} from 'axios'
import AuthService, {AUTH_LOCAL_STORAGE_KEY} from '../auth/auth.service'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

function getTimeZoneOffsetToUct() {
  return new Date().getTimezoneOffset() / 60
}

$api.interceptors.request.use((config: any) => {
  if (config?.url.indexOf('login') === -1) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)}`
  }
  config.headers.timezoneOffset = getTimeZoneOffsetToUct()

  return config
})

$api.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      window.location.href = `/auth/login`

      AuthService.clearLocalStorage()

      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default class RequestsService {
  static async getMethod<T>(url: string, query?: any): Promise<AxiosResponse<T>> {
    return $api.get(url, {
      params: query,
    })
  }

  static async postMethod<T>(url: string, payload?: any, config?: any): Promise<AxiosResponse<T>> {
    return $api.post<T>(url, payload, config)
  }

  static async putMethod<T>(url: string, payload?: any, config?: any): Promise<AxiosResponse<T>> {
    return $api.put<T>(url, payload, config)
  }

  static async patchMethod<T>(url: string, payload?: any, config?: any): Promise<AxiosResponse<T>> {
    return $api.patch<T>(url, payload, config)
  }

  static async deleteMethod<T>(url: string, config?: any): Promise<AxiosResponse<T>> {
    return $api.delete<T>(url, config)
  }
}
