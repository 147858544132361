import {AxiosResponse} from 'axios'
import RequestsService from '../base/requests.service'
import {APIRoutes} from '../../constants/apiRoutes'
import {IAuthResult} from '../../models/auth.model'
import {IResultResponse} from '../../models/response/result.model'

export let Buffer = require('buffer').Buffer

interface IForgotPasswordChange {
  newPassword: string
  resetPasswordToken: string
}

function createAuthHash(username: string, password: string) {
  return Buffer.from(`${username}:${password}`).toString('base64')
}

export const AUTH_LOCAL_STORAGE_KEY = '15824dd7-e5f7-4131-0e8f-69728a72a106'

export default class AuthService {
  static async login(
    login: string,
    password: string
  ): Promise<AxiosResponse<IResultResponse<IAuthResult>>> {
    return RequestsService.postMethod<IResultResponse<IAuthResult>>(APIRoutes.AUTH_LOGIN, null, {
      headers: {
        Authorization: `Basic ${createAuthHash(login, password)}`,
      },
    })
  }

  static async forgotPasswordRequest(email: string): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(APIRoutes.AUTH_FORGOT_PASSWORD_REQUEST, {
      email,
    })
  }

  static async forgotPasswordChange(
    data: IForgotPasswordChange
  ): Promise<AxiosResponse<IResultResponse<any>>> {
    return RequestsService.putMethod<IResultResponse<any>>(
      APIRoutes.AUTH_FORGOT_PASSWORD_CHANGE,
      data
    )
  }

  static clearLocalStorage = () => {
    if (!localStorage) {
      return
    }

    try {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
      localStorage.removeItem('app.Authorized')
    } catch {}
  }

  static setTokenLocalStorage = (token: string) => {
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token)
    localStorage.setItem('app.Authorized', 'true')
  }

  static getIsAuth: boolean = !!localStorage.getItem('app.Authorized')

  static geAuthToken = (): string | null => {
    return localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  }

  static logOut = () => {
    AuthService.clearLocalStorage()
  }
}
